import React from "react";
import PageNavigation from "../../components/UI/PageNavigation";
import s from "./StatutesPage.module.scss";

const StatutesPage = () => {
  return (
    <main>
      <section>
        <div className={s.container}>
          <PageNavigation />

          <h3 className={s.title}>Regulamin</h3>

          <ol className={s.text_area}>
            <li>
              Regulamin Sprzedaży Detalicznej firmy NETIFY SPÓŁKA Z OGRANICZONĄ
              ODPOWIEDZIALNOŚCIĄ
            </li>
            <li>
              Postanowienia ogólne
              <ol>
                <li>
                  Niniejszy regulamin określa zasady dokonywania zakupów oraz
                  realizacji zamówień na produkty oferowane przez firmę NETIFY
                  Spółka z Ograniczoną Odpowiedzialnością, z siedzibą przy ul.
                  Elektronowa 2, 03-219 Warszawa, NIP: 5243000103, REGON:
                  527929200, wpisaną do Krajowego Rejestru Sądowego pod numerem
                  KRS: 0001091182.
                </li>
                <li>
                  Regulamin ma na celu zapewnienie klarowności i przejrzystości
                  w relacjach między firmą a Klientem oraz określenie praw i
                  obowiązków stron.
                </li>
              </ol>
            </li>
            <li>
              Dane kontaktowe
              <ol>
                <li>
                  Adres siedziby firmy: ul. Elektronowa 2, 03-219 Warszawa.
                </li>
                <li>
                  Adres e-mail:{" "}
                  <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a>.
                </li>
                <li>
                  Numer telefonu kontaktowego:{" "}
                  <a href="tel:+48575627997">+48575627997</a>.
                </li>
              </ol>
            </li>
            <li>
              Zamówienia
              <ol>
                <li>
                  Klient może składać zamówienia na produkty oferowane przez
                  firmę za pośrednictwem strony internetowej [adres strony
                  internetowej] lub bezpośrednio w domach sprzedaży wysyłkowej.
                </li>
                <li>
                  Warunki i szczegóły zamówienia, w tym ceny, dostępność
                  produktów oraz czas realizacji zamówienia, są określone na
                  stronie internetowej firmy oraz mogą być uzgodnione
                  indywidualnie z Klientem.
                </li>
              </ol>
            </li>
            <li>
              Płatności
              <ol>
                <li>
                  Firma akceptuje następujące formy płatności:
                  <ol>
                    <li>Przelewy bankowe na wskazane konto bankowe firmy.</li>
                    <li>
                      Płatności kartą kredytową/debetową za pośrednictwem
                      systemu PayU.
                    </li>
                    <li>Płatności przy odbiorze (za pobraniem).</li>
                  </ol>
                </li>
                <li>
                  Warunki płatności, w tym terminy i szczegóły, są określone na
                  stronie internetowej firmy oraz mogą być uzgodnione
                  indywidualnie z Klientem.
                </li>
              </ol>
            </li>
            <li>
              Warunki dostawy
              <ol>
                <li>
                  Firma realizuje dostawy za pośrednictwem:
                  <ol>
                    <li>InPost Paczkomaty,</li>
                    <li>Dostawa kurierska.</li>
                  </ol>
                </li>
                <li>
                  Koszty dostawy oraz czas realizacji są uzależnione od
                  wybranego sposobu dostawy i są podawane podczas składania
                  zamówienia.
                </li>
                <li>
                  Zamówienia są wysyłane w ciągu 3 dni roboczych od momentu
                  potwierdzenia zamówienia, chyba że w opisie produktu
                  zaznaczono inaczej.
                </li>
              </ol>
            </li>
            <li>
              Reklamacje
              <ol>
                <li>
                  Klient ma prawo zgłosić reklamację dotyczącą jakości produktów
                  lub niezgodności zamówienia.
                </li>
                <li>
                  Reklamacje można składać pisemnie na adres siedziby firmy lub
                  drogą elektroniczną na adres e-mail wskazany w punkcie 3.2.
                </li>
                <li>
                  Reklamacje rozpatrywane są w terminie 14 dni roboczych od daty
                  ich otrzymania przez firmę. Klient zostanie poinformowany o
                  decyzji w sprawie reklamacji drogą elektroniczną lub
                  telefonicznie.
                </li>
              </ol>
            </li>
            <li>
              Zwrot
              <ol>
                <li>
                  Klient ma prawo do zwrotu zakupionych produktów bez podania
                  przyczyny w ciągu 14 dni kalendarzowych od daty otrzymania
                  przesyłki.
                </li>
                <li>
                  Aby dokonać zwrotu, Klient powinien poinformować firmę o
                  swojej decyzji drogą pisemną na adres siedziby firmy lub drogą
                  elektroniczną na adres e-mail wskazany w punkcie 3.2.
                </li>
                <li>
                  Zwrot produktów powinien nastąpić niezwłocznie, nie później
                  niż 14 dni kalendarzowych od dnia poinformowania firmy o
                  decyzji o odstąpieniu od umowy.
                </li>
                <li>
                  Koszty zwrotu produktów ponosi Klient, chyba że firma zgodzi
                  się je pokryć.
                </li>
                <li>
                  Zwrot płatności zostanie dokonany przy użyciu tych samych
                  metod płatności, które zostały użyte przez Klienta podczas
                  pierwotnej transakcji, chyba że Klient wyraźnie zgodzi się na
                  inne rozwiązanie. Zwrot płatności zostanie dokonany
                  niezwłocznie, nie później niż w ciągu 14 dni kalendarzowych od
                  dnia otrzymania zwróconych produktów przez firmę.
                </li>
              </ol>
            </li>
            <li>
              Ochrona danych osobowych
              <ol>
                <li>
                  Firma dba o ochronę danych osobowych Klientów zgodnie z
                  obowiązującymi przepisami prawa, w tym z Rozporządzeniem
                  Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                  kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
                  przetwarzaniem danych osobowych i w sprawie swobodnego
                  przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                  (RODO).
                </li>
                <li>
                  Informacje dotyczące przetwarzania danych osobowych Klientów
                  znajdują się w Polityce Prywatności, dostępnej na stronie
                  internetowej firmy.
                </li>
              </ol>
            </li>
            <li>
              Postanowienia końcowe
              <ol>
                <li>
                  Netify Spółka z Ograniczoną Odpowiedzialnością zastrzega sobie
                  prawo do zmiany niniejszego regulaminu. Zmiany wchodzą w życie
                  z dnia ich opublikowania na stronie internetowej firmy.
                </li>
                <li>
                  Regulamin obowiązuje od dnia [data wejścia w życie
                  regulaminu].
                </li>
                <li>
                  W sprawach nieuregulowanych niniejszym regulaminem mają
                  zastosowanie odpowiednie przepisy prawa polskiego.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </section>
    </main>
  );
};

export default StatutesPage;
