import React from "react";
import { Routes, Route } from "react-router-dom";
import './assets/reset.scss';
import './components/Header/Header'
import ProductDetails from "./pages/ProductDetails";
import NotFoundPage from "./pages/NotFoundPage";
import Layout from "./components/Layout";
import ShipmentPage from './pages/ShipmentPage/ShipmentPage'
import PaymentMetodsPage from "./pages/PaymentMethodsPage/PaymentMethodsPage";
import StatutesPage from "./pages/StatutesPage/StatutesPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import WithdrawalPage from "./pages/WithdrawalPage/WithdrawalPage";
import { ProductProvider } from "./context/ProductContext";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Main from "./pages/Main";

function App() {
  return (
    <ProductProvider>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout/>}>
            <Route index element={<Main/>}/>
            <Route path="/:productSlug" element={<ProductDetails/>}/>
            <Route path="/paymentmetods" element={<PaymentMetodsPage/>}/>
            <Route path="/shipment" element={<ShipmentPage/>}/>
            <Route path="/statutes" element={<StatutesPage/>}/>
            <Route path="/privatepolice" element={<PrivacyPolicyPage/>}/>
            <Route path="/withdrawal" element={<WithdrawalPage/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </Route>
      </Routes>
    </ProductProvider>
  );
}

export default App;
