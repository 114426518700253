import React from "react";
import s from "./PageNavigation.module.scss";

const PageNavigation = () => {
  const onClickBack = () => {
    window.history.back();
  };

  return (
    <div className={s.back_row}>
      <button type="button" onClick={onClickBack}>
        Powrót
      </button>
    </div>
  );
};

export default PageNavigation;
