import { useState, useEffect } from 'react';
import s from './RecommendProducts.module.scss';

const RecommendProducts = ({ products, setFieldValue }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        const validProducts = selectedProducts.filter(product => product.quantity > 0);
        if (validProducts.length > 0) {
            setFieldValue('recommend_products', validProducts);
        } else {
            setFieldValue('recommend_products', []);
        }
    }, [selectedProducts, setFieldValue]);

    const handleProductChange = (id, quantity) => {
        setSelectedProducts(prevSelected => {
            const productIndex = prevSelected.findIndex(product => product.product === id);
            if (productIndex !== -1) {
                const updatedProducts = [...prevSelected];
                updatedProducts[productIndex].quantity = quantity;
                return updatedProducts;
            } else {
                return [...prevSelected, { product: id, quantity }];
            }
        });
    };

    return (
        <div className={s.recommend_products_col}>
            <h3 className={s.title}>Kupuj razem i oszczędzaj</h3>

            <div className={s.recommend_items}>
                {products.map(({ id, img, description, name, price }) => (
                    <RecommendProduct
                        key={id}
                        id={id}
                        img={img}
                        name={name}
                        price={price}
                        description={description}
                        onChange={handleProductChange}
                    />
                ))}
            </div>
        </div>
    );
};

const RecommendProduct = ({ id, img, name, price, description, onChange }) => {
    const [count, setCount] = useState(0);

    const handleIncrease = () => {
        setCount(prevCount => {
            const newCount = prevCount + 1;
            onChange(id, newCount);
            return newCount;
        });
    };

    const handleDecrease = () => {
        setCount(prevCount => {
            const newCount = Math.max(prevCount - 1, 0);
            onChange(id, newCount);
            return newCount;
        });
    };

    return (
        <div className={s.recommend_product_item}>
            <label htmlFor={`recommend_product_${id}`}>
                <div className={s.main_content}>
                    <img src={img} alt="additionalProductImg" />
                    <div className={s.text}>
                        <h3>{name}</h3>
                        <p>{description}</p>
                    </div>
                </div>

                <div className={s.price_counter}>
                    <p>{price} zł</p>
                    <div className={s.counter_controls}>
                        <button type="button" className={s.remove_bt} onClick={handleDecrease}>-</button>
                        <span>{count}</span>
                        <button type="button" className={s.add_bt} onClick={handleIncrease}>+</button>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default RecommendProducts;
