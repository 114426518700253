import s from "./Header.module.scss";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink ,useLocation} from "react-router-dom";
import logo from "../../resources/header/trendyto_small_logo.png";
import ShoppingCart from "../../resources/icon/shopping_cart.png";
import { useProduct } from "../../context/ProductContext";
import Timer from "../UI/Timer/Timer";

const Header = () => {
  const { productSlug } = useProduct();
  const {pathname} = useLocation();


  return (
    <header>
      <div className={s.container}>
        <div className={s.baner_row}>
          <div className={s.baner}>
            <Timer title="🛍️ Tylko niższa cena: 🛍️" />

            <ScrollLink to={`${pathname == '/' ? 'showProduct':'orderForm'}`} smooth={true} duration={500}>
              <button className={s.buy_now}>
                <img src={ShoppingCart} alt="shoppingCartIcon" />
                Kup teraz
              </button>
            </ScrollLink>
          </div>
        </div>

        <RouterLink to={`/`}>
          <img src={logo} alt="trendytoLogo" className={s.logo} />
        </RouterLink>
      </div>
    </header>
  );
};

export default Header;
