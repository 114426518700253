import React from 'react'
import PageNavigation from '../../components/UI/PageNavigation'
import s from './PaymentMethodsPage.module.scss'

const AboutUs = () => {


  return (

    <main>
        <section>

          <div className={s.container}>

          <PageNavigation/>


            <h3 className={s.title}>
              Sposoby płatności
            </h3>

            <ul className={s.text_area}>
                <li>
                    Postanowienia ogólne
                    <ul>
                        <li>
                            Niniejszy regulamin określa zasady dokonywania płatności za towary zamówione w firmie Netify Spółka z ograniczoną odpowiedzialnością, z siedzibą przy ul. Elektronowa 2, 03-219 Warszawa, NIP: 5243000103, REGON: 527929200, KRS: 0001091182.
                        </li>
                    </ul>
                </li>
                <li>
                    Sposoby płatności
                    <ul>
                        <li>
                            Płatność za pośrednictwem PayU
                            <ul>
                                <li>
                                    Netify Sp. z o.o. oferuje możliwość dokonywania płatności za pośrednictwem systemu PayU, który umożliwia szybkie, bezpieczne i wygodne realizowanie transakcji online.
                                </li>
                                <li>
                                    W ramach systemu PayU dostępne są następujące formy płatności:
                                    <ul>
                                        <li>Płatność kartą kredytową/debetową (Visa, MasterCard).</li>
                                        <li>Przelewy online z wybranych banków.</li>
                                        <li>Płatności mobilne BLIK.</li>
                                        <li>Raty PayU – możliwość rozłożenia płatności na raty.</li>
                                        <li>PayU Express – szybkie płatności jednym kliknięciem.</li>
                                    </ul>
                                </li>
                                <li>
                                    Aby dokonać płatności za pomocą PayU, należy wybrać tę opcję podczas składania zamówienia i postępować zgodnie z instrukcjami wyświetlanymi na ekranie.
                                </li>
                                <li>
                                    Wszystkie transakcje realizowane za pośrednictwem PayU są szyfrowane i zabezpieczone, co zapewnia pełne bezpieczeństwo danych.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    Potwierdzenie płatności
                    <ul>
                        <li>
                            Po dokonaniu płatności za pośrednictwem PayU, klient otrzyma potwierdzenie transakcji na adres e-mail podany podczas składania zamówienia.
                        </li>
                        <li>
                            W przypadku jakichkolwiek problemów z płatnością, klient powinien niezwłocznie skontaktować się z działem obsługi klienta Netify Sp. z o.o. pod adresem e-mail: [adres e-mail] lub telefonicznie: [numer telefonu].
                        </li>
                    </ul>
                </li>
                <li>
                    Reklamacje dotyczące płatności
                    <ul>
                        <li>
                            W przypadku reklamacji dotyczących płatności, klient może zgłosić reklamację do działu obsługi klienta Netify Sp. z o.o. pod adresem e-mail: [adres e-mail] lub telefonicznie: [numer telefonu].
                        </li>
                        <li>
                            Reklamacje rozpatrywane są w ciągu 14 dni od daty ich zgłoszenia.
                        </li>
                    </ul>
                </li>
                <li>
                    Postanowienia końcowe
                    <ul>
                        <li>
                            Netify Sp. z o.o. zastrzega sobie prawo do zmiany niniejszego regulaminu. Zmiany wchodzą w życie z dniem ich opublikowania na stronie internetowej firmy.
                        </li>
                        <li>
                            W sprawach nieuregulowanych niniejszym regulaminem mają zastosowanie odpowiednie przepisy prawa polskiego.
                        </li>
                    </ul>
                </li>
            </ul>

          </div>

        </section>

    </main>
  )
}

export default AboutUs