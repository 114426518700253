import React from "react"
import s from './ConfirmationCard.module.scss'
import InPostIcon from '../../../../resources/icon/inPost_Icon.png'


const ConfirmationCard = ({ city, post_code, province, street })=> {

    return(
        <div className={s.confirmation_card}>

            <div className={s.text_area}>

                <p className={s.address_text}>
                    {`${province} ${street}`}
                </p>

                <p className={s.house_post_text}>
                    {post_code}
                </p>

                <p className={s.city_text}>
                    {city}
                </p>

            </div>

            <img src={InPostIcon} alt="inpostImg" />

        </div>
    )

}

export default ConfirmationCard