import {useMemo} from 'react'
import s from './Range.module.scss';
import BoxIco from '../../../resources/icon/box_delivery.png'
import DiscountButton from '../../UI/DiscountButton/DiscountButton';
import { Link as ScrollLink } from 'react-scroll';

const Range = ({variations, discount, price}) => {

  return (
    <section>
        <div className={s.container}>
            <h2>Co jest zawarte?</h2>

            <div className={s.range_row}>

                {
                    variations.map(({id, description, img})=>{

                        return(
                            <div className={s.card} key={id}>

                                <div className={s.card_body}>
                                    <img src={img} alt="productImg" className={s.product_img}/>

                                    <p className={s.characteristics}>
                                        {description}
                                    </p>

                                    <p className={s.additional_info}>
                                        <img src={BoxIco} alt="boxIcon" />
                                        Płatność przy odbiorze
                                    </p>

                                </div>

                                <ScrollLink to="orderForm" smooth={true} duration={500}>
                                    <DiscountButton originalPrice={price} discountPercent={discount} size="small" />
                                </ScrollLink>
                            </div>

                        )

                    })

                }
                
            </div>

        </div>

    </section>
  )

}

export default Range