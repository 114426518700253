
import React from 'react'
import { Link as ScrollLink } from 'react-scroll';
import s from '../ProductShow/ProductShow.module.scss'
import StarRating from '../../UI/StarRating/StarRating'
import AceptIco from '../../../resources/icon/accept.png';
import DiscountButton from '../../UI/DiscountButton/DiscountButton';
import Pl from '../../../resources/icon/Pl.svg';
import AtantionIco from '../../../resources/icon/atantion.png';
import DeliveryIco from '../../../resources/icon/delivery_bike.png';
import ProductSlider from '../../UI/ProductSlider/ProductSlider';


const ProductShow = ({ productImg, discount, price, description}) => {

    return (
    <section>
        <div className={s.container}>

            <ProductSlider imgs={productImg}/>

            <div className={s.product_card}>

                <h3>{description}</h3>
                
                <div className={s.star_rating_block}>
                    <StarRating rating={5} size='20px'/>

                    <p>
                        {4.5}/5 (264 oceny i 66 recenzji)
                    </p>

                </div>

                <ul className={s.product_characteristics}>
                    <li>
                        <img src={AceptIco} alt="aceptIcon"/>
                        Oddychający materiał
                    </li>
                    <li>
                        <img src={AceptIco} alt="aceptIcon"/>
                        Maksymalny komfort
                    </li>
                    <li>
                        <img src={AceptIco} alt="aceptIcon"/>
                        Idealny ochraniacz na materac
                    </li>
                </ul>

                <div className={s.price_payment_info}>

                    <div className={s.bt_price}>
                    <ScrollLink to="orderForm" smooth={true} duration={500}>
                        <DiscountButton size = 'small' originalPrice={price} discountPercent={discount} link='3'/>
                    </ScrollLink>
                    </div>

                    <img className={s.img_payments_metods} src={Pl} alt="paymentsMetods" />

                </div>

                <div className={s.warnings_col}>

                    <p className={s.warning_msg}>
                        <img src={AtantionIco} alt="atantionIcon" />
                        Aktualnie na magazynie: Zostało tylko 10 sztuk.
                    </p>

                    <p className={s.delivery_msg}>
                        <img src={DeliveryIco} alt="deliveryIco" />
                        Szybka dostawa
                    </p>

                </div>

            </div>
            
        </div>

    </section>
  )
}

export default ProductShow