import { useMemo } from "react";
import { Link as ScrollLink } from "react-scroll";
import ShoppingCart from "../../../resources/icon/shopping_cart.png";
import s from "./PaymentNavigator.module.scss";

const PaymentNavigator = ({ discount, price, isVisible, ref}) => {
  const discountedPrice = useMemo(() => {
    if (price) {
      return (price - (price * discount) / 100).toFixed(2);
    }
    return null;
  }, [price]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={s.container} ref={ref}>
      <div className={s.payment_navigator}>
        <div className={s.price}>
          <p className={s.old_price}>{price} zł</p>
          <p className={s.new_price}>
            {discountedPrice} zł
            <span>{discount}%</span>
          </p>
        </div>

        <ScrollLink to="orderForm" smooth={true} duration={500}>
          <button type="button" className={s.buy_now}>
            <img src={ShoppingCart} alt="shoppingCartIcon" />
            Kup teraz
          </button>
        </ScrollLink>
      </div>
    </div>
  );
};

export default PaymentNavigator;
