import { useState } from 'react';
import { useParams } from 'react-router';
import s from './OrderForm.module.scss';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ProductSelector from '../../UI/ProductSelector /ProductSelector';
import DeliverySpeedSelecot from '../../UI/DeliverySpeedSelecot/DeliverySpeedSelecot';
import DeliveryMetods from '../../UI/DeliveryMetods/DeliveryMetods';
import PaymentMetods from '../../UI/PaymentMetods/PaymentMetods';
import BuyNowIcon from '../../../resources/icon/buy_now.png';
import PolandIcon from '../../../resources/icon/PolandIcon.svg'
import RecommendProducts from '../../UI/RecommendProducts/RecommendProducts';
import TransBadgesSafe from '../../../resources/icon/trans-badges-safe-1.png';
import PaymentsRowImg from '../../../resources/icon/payments_img_row.svg'
import Timer from '../../UI/Timer/Timer';

const initialValues = {
    product: 0,
    first_name: '',
    last_name: '',
    phone_num: '',
    email: ' ',
    address: '',
    house_number: '',
    postal_code: '',
    city: '',
    delivery_method: '',
    payment_type: '',
    recommend_products: [
        {
            product: '',
            quantity: 0
        }
    ],
    order_items: [
        {
            variation: '',
            quantity: 0
        },
    ]
};

const validationSchema = Yup.object({
    first_name: Yup.string()
        .min(2, "Minimum 2 znaki!")
        .required('To pole jest wymagane!'),
    last_name: Yup.string()
        .min(2, "Minimum 2 znaki!")
        .required('To pole jest wymagane!'),
    phone_num: Yup.string()
        .matches(/^(?:\+?\d{1,3})?\d{9,14}$/, 'Nieprawidłowy format numeru telefonu')
        .required('Numer telefonu jest wymagany'),
    email: Yup.string()
        .email('Nieprawidłowy format email'),
    address: Yup.string()
        .min(5, 'Adres musi mieć co najmniej 5 znaków')
        .required('Adres jest wymagany'),
    house_number: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, 'Numer domu może zawierać tylko cyfry i litery')
        .required('Numer domu jest wymagany'),
    postal_code: Yup.string()
        .matches(/^\d{2}-\d{3}$/, 'Nieprawidłowy format kodu pocztowego')
        .required('Kod pocztowy jest wymagany'),
    city: Yup.string()
        .min(2, 'Miasto musi mieć co najmniej 2 znaki')
        .required('Miasto jest wymagane'),
    order_items: Yup.array().of(
        Yup.object().shape({
            variation: Yup.string().required('Wybierz wariant produktu'),
            quantity: Yup.number().required('Quantity is required').positive().integer()
        })
    )
});

const OrderForm = ({ variations, price, recommend_products, product_id}) => {
    const [error, setError] = useState();

    return (
        <section id='orderForm'>
            <div className={s.container}>
                <h2>Formularz zamówienia</h2>

                <div className={s.form_col}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(data) => {
                            
                            const payloud = {
                                product: Number(product_id),
                                first_name: data.first_name,
                                last_name: data.last_name,
                                phone_num: data.phone_num,
                                email: data.email,
                                address: data.address,
                                house_number: data.house_number,
                                postal_code: data.postal_code,
                                city: data.city,
                                delivery_method: data.delivery_method,
                                recommend_products:data.recommend_products,
                                order_items: data.order_items,
                                payment_type: data.payment_type
                            };

                            const createOrder = async () => {
                                try {
                                    const response = await axios.post(`${process.env.REACT_APP_TRENDYTU_API_URL}/api/orders/`,payloud);
                                    const responseData = response.data;
                                    
                                    if(responseData.payment_url){
                                        window.location.href = responseData.payment_url;
                                    }

                                } catch (error) {
                                    setError(error.message);
                                }

                            };

                            createOrder();

                        }}
                    >
                        {({ setFieldValue, errors, touched }) => (
                            <Form className={s.order_form}>
                                <div className={s.inputs_col}>
                                    <ProductSelector
                                        productVariant={variations}
                                        price={price}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <div className={s.payer_info_col}>
                                        <h3>Informacje o płatniku</h3>

                                        <div className={s.fio_row}>
                                            <div>
                                                <Field type="text" name="first_name" placeholder="Imię" />
                                                <ErrorMessage name="first_name" component="div" className={s.errorMesage} />
                                            </div>
                                            <div>
                                                <Field type="text" name="last_name" placeholder="Nazwisko" />
                                                <ErrorMessage name="last_name" component="div" className={s.errorMesage} />
                                            </div>
                                        </div>

                                        <div className={s.phone_input}>
                                            <Field type="text" name="phone_num" placeholder="Numer telefonu" />
                                            <p>Numer będzie używany tylko do informacji o statusie Twojej paczki.</p>
                                            <ErrorMessage name="phone_num" component="div" className={s.errorMesage} />
                                        </div>

                                        <div className={s.email_input}>
                                            <Field type="email" name="email" placeholder="E-mail" />
                                            <p>Adres email będzie używany tylko do wystawienia faktury Vat lub paragonu.</p>
                                            <ErrorMessage name="email" component="div" className={s.errorMesage} />
                                        </div>
                                    </div>

                                    <div className={s.delivery_col}>
                                        <h3>Wybór dostawy</h3>

                                        <div className={s.card_info}>
                                            <img src={PolandIcon} alt="polandIcon" />

                                            Wspieraj lokalną polską firmę - Dostawa z polskiego magazynu.
                                        </div>

                                        <DeliverySpeedSelecot setFieldValue={setFieldValue} />
                                    </div>

                                    <div className={s.delivery_metods}>
                                        <h3>Adres dostawy</h3>

                                        <DeliveryMetods setFieldValue={setFieldValue} />
                                    </div>
                                </div>

                                <div className={s.payment_col}>
                                    <div className={s.select_payment_metods_body}>
                                        <PaymentMetods setFieldValue={setFieldValue} />

                                        <button type="submit" className={s.send_data_bt}>
                                            <img src={BuyNowIcon} alt="icon" />
                                            Zatwierdź
                                        </button>

                                        <div className={s.protection_user_rights_col}>
                                            <Timer size='bigTimer' title={'⌛ Akcja trwa jeszcze ⌛'}/>

                                            <img src={PaymentsRowImg} alt="paymentsImg" />
                                            <img src={TransBadgesSafe} alt="TransBadgesSafeimg"/>

                                            <div className={s.text}>
                                                <p>14-dni na zwrot bez podania przyczyny</p>
                                                <p>Płatność przy odbiorze</p>
                                            </div>

                                        </div>
                                    </div>

                                    <RecommendProducts setFieldValue={setFieldValue} products={recommend_products}/>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </section>
    );
};

export default OrderForm;