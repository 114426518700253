import React, {useEffect, useState} from "react";
import axios from 'axios';
import AboutUs from "../components/Sections/AboutUs";
import ShowProducts from "../components/ShowProducts";
import Loader from "../components/UI/Loader/Loader";
import ErrorMessage from "../components/UI/ErrrorMesage/ErrorMesage";
import Faq from "../components/Sections/FAQ/Faq";
import MainBaner from "../components/UI/MainBaner";

const Main = () => {
  const [product, setProduct] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_TRENDYTU_API_URL}/api/products/`);
        setProduct(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  },[]);

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <ErrorMessage error={error} />;
  } else if (!product) {
    return <p>No data available</p>;
  }


  return (
    <main>
      <MainBaner/>
      <ShowProducts products={product} />
      <AboutUs/>
      <Faq/>
    </main>
  );
};

export default Main;
