import { useState } from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import s from './Reviews.module.scss';
import StarRating from '../../UI/StarRating/StarRating'
import ModalWindow from '../../UI/ModalWindow/ModalWindow';

const animationPartner = {
  hidden: {
    scale: 0,
  },
  visible: (custom) => ({
    scale: 1,
    transition: { delay: custom * 0.15 },
  }),
};

const Reviews = ({ productReviews }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const initialValues = {
    rating: 0,
    comment: '',
    email: '',
  };

  const validationSchema = Yup.object({
    rating: Yup.number().min(1, 'Wybierz ocenę').max(5, 'Maksymalna ocena 5').required('Ocena jest wymagana'),
    comment: Yup.string().required('Komentarz jest wymagany'),
    email: Yup.string().email('Nieprawidłowy email').required('Email wymagany'),
  });

  const handleSubmit = (values) => {
    handleCloseModal();
  };

  return (
    <section>
      <div className={s.container}>
        <h2>Opinie</h2>
        <div className={s.reviews_slider}>
          {productReviews.map(({ id, message, rating_stars, created_by, creator_img, img }, i) => (
            <motion.div
              initial="hidden"
              viewport={{ amount: 1, once: true }}
              whileInView="visible"
              variants={animationPartner}
              custom={i}
              className={s.review_card}
              key={id}
            >
              <img className={s.photoByCustomer} src={img} alt="productPhoto" />
              <p className={s.text_review}>{message}</p>
              <div className={s.customer_data}>
                <img src={creator_img} alt="customerPhoto" />
                <div className={s.stars_name}>
                  <StarRating rating={rating_stars} size="14px" />
                  <p>{created_by}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ setFieldValue, values }) => (
              <Form className={s.review_form}>
                
                <div className={s.rating}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`${s.star} ${values.rating >= star ? s.filled : ''}`}
                      onClick={() => setFieldValue('rating', star)}
                    >
                      ★
                    </span>
                  ))}
                  <ErrorMessage name="rating" component="div" className={s.error} />
                </div>

                <div className={s.form_group}>
                  <Field as="textarea" name="comment" placeholder="Komentarz" />
                  <ErrorMessage name="comment" component="div" className={s.error} />
                </div>

                <div className={s.form_group}>
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage name="email" component="div" className={s.error} />
                </div>

                <button type="submit">Dodaj recenzję</button>
              </Form>
            )}
          </Formik>
        </ModalWindow>

        <div className={s.bt_new_review}>
          <button onClick={handleOpenModal}>Wystawić opinię</button>
          <p>Bolea obsługuje 1 651 467 klientów.</p>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
