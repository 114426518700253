import {useMemo} from 'react';
import s from './DiscountButton.module.scss';

const DiscountButton = ({ originalPrice, discountPercent, link, size}) => {

    const discountedPrice = useMemo(() => {
        if (originalPrice) {
          return (originalPrice - (originalPrice * discountPercent / 100)).toFixed(2);
        }
        return null;
    }, [originalPrice])

return (
    <a href={link} className={size === 'small' ? s.small_discountBt : s.discountButton}>
        <div className={s.price_info}>

            <p className={s.old_price}>
                {originalPrice} zł
            </p>

            <p className={s.new_price}>
                {parseFloat(discountedPrice).toFixed(2)} zł
            </p>

        </div>

        <div className={s.order_text}>
            Zamów
        </div>

        <div className={s.discountTag}>
            <p>
                -{discountPercent}%
            </p> 
        </div>
    </a>
  );
};

export default DiscountButton;

