import React, { useState, useEffect } from 'react';
import s from './Timer.module.scss';

const Timer = ({ size, title }) => {
    const [timeLeft, setTimeLeft] = useState(() => {
        const existingEndTime = localStorage.getItem('timerEndTime');
        if (existingEndTime) {
            return calculateTimeLeft(existingEndTime);
        } else {
            const newEndTime = setEndTime();
            return calculateTimeLeft(newEndTime);
        }
    });

    useEffect(() => {
        const timer = setInterval(() => {
            const existingEndTime = localStorage.getItem('timerEndTime');
            setTimeLeft(calculateTimeLeft(existingEndTime));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft(endTime) {
        const difference = new Date(endTime) - new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
                minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
                seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0')
            };
        } else {
            const newEndTime = setEndTime();
            timeLeft = calculateTimeLeft(newEndTime);
        }

        return timeLeft;
    }

    function setEndTime() {
        const endTime = new Date();
        endTime.setHours(endTime.getHours() + 24);
        localStorage.setItem('timerEndTime', endTime);
        return endTime;
    }

    return (
        <div className={`${s.timer_body} ${size === 'bigTimer' ? s.big_timer : ''}`}>
            <p>{title}</p>
            <h3>{`${timeLeft.hours} : ${timeLeft.minutes} : ${timeLeft.seconds}`}</h3>
        </div>
    );
}

export default Timer;
