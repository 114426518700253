import { useState, useEffect } from 'react';
import s from './ProductSelector.module.scss';
import { ErrorMessage } from 'formik';

const ProductSelector = ({ productVariant, price, setFieldValue, errors, touched }) => {
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [selectedProducts, setSelectedProducts] = useState([{ variation: null, quantity: 1 }]);
    const [lastSelectedQuantity, setLastSelectedQuantity] = useState(null);

    useEffect(() => {
        setFieldValue('order_items', selectedProducts);
    }, [selectedProducts, setFieldValue]);

    const onClickQuantityBt = (event) => {
        const newQuantity = parseInt(event.currentTarget.getAttribute('data-quantity'), 10);
        if (newQuantity === lastSelectedQuantity) {
            return; // Если кнопка уже была выбрана, ничего не делаем
        }
        setLastSelectedQuantity(newQuantity);

        if (newQuantity < currentQuantity) {
            setSelectedProducts((prevSelected) => prevSelected.slice(0, newQuantity));
        } else {
            setSelectedProducts((prevSelected) => {
                const newProducts = [...prevSelected];
                while (newProducts.length < newQuantity) {
                    newProducts.push({ variation: null, quantity: 1 });
                }
                return newProducts;
            });
        }
        setCurrentQuantity(newQuantity);
    };

    const handleVariantChange = (productIndex, variantId) => {
        setSelectedProducts((prevSelected) =>
            prevSelected.map((product, index) =>
                index === productIndex ? { ...product, variation: variantId } : product
            )
        );
    };

    const calculatePrice = (quantity) => {
        const basePrice = price;
        let discount = 0;

        if (quantity === 2) {
            discount = 0.2; // 20% discount
        } else if (quantity === 3) {
            discount = 0.3; // 30% discount
        }

        const finalPrice = basePrice - basePrice * discount;
        return finalPrice.toFixed(2);
    };

    return (
        <div className={s.product_selector_col}>
            <div className={s.quantity_row}>
                {[1, 2, 3].map((quantity) => (
                    <div
                        key={quantity}
                        data-quantity={quantity}
                        className={quantity === currentQuantity ? s.quantity_bt_active : s.quantity_bt}
                        onClick={onClickQuantityBt}
                        style={{ pointerEvents: quantity === lastSelectedQuantity ? 'none' : 'auto' }} // Блокировка повторного нажатия
                    >
                        <h3 className={s.quantity}>{quantity}x</h3>
                        <div className={s.price_col}>
                            <p className={s.price}>{calculatePrice(quantity)} zł</p>
                            <p className={s.a_piece}>ZA SZTUKĘ</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className={s.product_variants_col}>
                {selectedProducts.map((product, productIndex) => (
                    <div key={productIndex} className={s.product_variant_container}>
                        <ProductVariant
                            productIndex={productIndex}
                            product={product}
                            productVariant={productVariant}
                            handleVariantChange={handleVariantChange}
                        />
                        <ErrorMessage
                            name={`order_items[${productIndex}].variation`}
                            component="div"
                            className={s.error_message}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const ProductVariant = ({ productIndex, product, productVariant, handleVariantChange }) => {
    const variation = productVariant.find(variant => variant.id === product.variation);

    return (
        <div className={s.product_variant} key={productIndex}>
            <p className={s.description_product_variant}>
                Product {productIndex + 1}: <h3>{variation ? variation.description : ""}</h3>
            </p>

            <div className={s.img_checkbox_row}>
                {productVariant.map(({ id, description, img }) => (
                    <label htmlFor={`variant-${productIndex}-${id}`} key={id}>
                        <input
                            type="radio"
                            name={`variant-${productIndex}`}
                            id={`variant-${productIndex}-${id}`}
                            checked={product.variation === id}
                            onChange={() => handleVariantChange(productIndex, id)}
                        />
                        <img src={img} alt={description} />
                    </label>
                ))}
            </div>
        </div>
    );
};

export default ProductSelector;
