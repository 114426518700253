import React from 'react';
import s from './ErrorMesage.module.scss';

const ErrorMessage = ({ error }) => {
  // Проверяем, что объект ошибки определен
  if (!error) {
    return null;
  }

  return (
    <div className={s.errorMessage}>
      <h3>
        {error.response && error.response.status ? error.response.status : 'Error'}
      </h3>
      <p>
        {error.message ? error.message : 'An unknown error occurred'}
      </p>
    </div>
  );
};

export default ErrorMessage;
