import React from 'react'
import s from '../StarRating/StarRating.module.scss';

const StarRating = ({rating,size}) => {
    const percentage = (rating / 5) * 100;

    return (
        <div className={s.starRating} style={{fontSize: size}}>
          <div className={s.starsOuter}>
            <div className={s.starsInner} style={{ width: `${percentage}%`}}></div>
          </div>
        </div>
      );
}

export default StarRating