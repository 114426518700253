import React from "react";
import s from "./AboutUs.module.scss";
import HappyMan from "../../../resources/main/happy_man.png";

const AboutUs = () => {
  return (
    <section>
      <div className={s.container}>
        <div className={s.baner_body}>
          <img src={HappyMan} alt="happyMan" />

          <div className={s.text_area}>
            <h3>Witamy w Trendytu!</h3>
            <p>
              Zapraszamy do naszego internetowego sklepu, gdzie znajdziesz
              najnowsze trendy w modzie i stylu życia. Oferujemy szeroki wybór
              produktów najwyższej jakości, w konkurencyjnych cenach. Nasza
              misja to dostarczenie Ci najlepszych doświadczeń zakupowych, z
              szybką dostawą i doskonałą obsługą klienta.
            </p>
            <p>
              Nie przegap naszych wyjątkowych ofert i promocji! Zarejestruj się
              już teraz i bądź na bieżąco z najnowszymi trendami.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
