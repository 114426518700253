import { useState, useEffect } from 'react';
import axios from 'axios';
import s from './DeliverySpeedSelecot.module.scss';

const DeliverySpeedSelecot = ({ setFieldValue }) => {
    const [deliverySpeedsV, setDeliverySpeedV] = useState([]);
    const [curentDeliveryS, setCurentDeliveryS] = useState(null); // Обновил начальное значение на null
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDeliverySpeedV = async () => {
            setLoading(true); // Начинаем загрузку данных
            try {
                const response = await axios.get(`${process.env.REACT_APP_TRENDYTU_API_URL}/api/orders/delivery-methods/`);
                const data = response.data;
                setDeliverySpeedV(data);

                if (data.length > 0) {
                    setCurentDeliveryS(data[0].id);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false); // Завершаем загрузку данных
            }
        };

        fetchDeliverySpeedV();
    }, []); // Пустой массив зависимостей для выполнения эффекта только один раз при монтировании компонента

    useEffect(() => {
        if (curentDeliveryS !== null) {
            setFieldValue('delivery_method', curentDeliveryS);
        }
    }, [curentDeliveryS, setFieldValue]);

    const onChangeCurentDel = (id) => {
        setCurentDeliveryS(id);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className={s.delivery_speed_col}>
            {deliverySpeedsV.map(({ id, name, img, description, price }) => (
                <div className={s.delivery_variant_body} key={id}>
                    <input
                        type="radio"
                        name="productSpeedDelivery"
                        id={`delivery-variant-${id}`}
                        checked={curentDeliveryS === id}
                        onChange={() => onChangeCurentDel(id)}
                    />
                    <label htmlFor={`delivery-variant-${id}`}>
                        <div className={s.radio_mark_col}>
                            <div className={s.radio_mark}></div>
                            <img src={img} alt={name} />
                        </div>
                        <div className={s.text_info}>
                            <p className={s.description}>{description}</p>
                            <p className={s.price_info}>
                                <b>{name} </b>
                                {price} zł
                            </p>
                        </div>
                    </label>
                </div>
            ))}
        </div>
    );
};

export default DeliverySpeedSelecot;
