import { useState, useEffect, useCallback } from 'react';
import { Field, ErrorMessage } from 'formik';
import { InpostGeowidget } from 'react-inpost-geowidget';
import s from './DeliveryMetods.module.scss';
import HouseIcon from '../../../resources/icon/house.png';
import InPostIcon from '../../../resources/icon/inPost_Icon.png';
import ModalWindow from '../../UI/ModalWindow/ModalWindow';
import ConfirmationCard from './ConfirmationCard/ConfirmationCard';

const DeliveryMetods = ({ setFieldValue }) => {  
    const [curentDeliveryMetod, setCurentDeliveryMetod] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [adressDetails, setAdressDetails] = useState();
    const [isConfirmationCardOpen, setConfirmationCardOpen] = useState(false);
    const TokenInPost = process.env.REACT_APP_INPOS_KEY;

    // Мемоизируем setFieldValue для предотвращения рекурсии
    const setFieldValueMemoized = useCallback((field, value) => {
        setFieldValue(field, value);
    }, [setFieldValue]);

    useEffect(() => {
        setFieldValueMemoized('delivery_method', curentDeliveryMetod);
    }, [curentDeliveryMetod, setFieldValueMemoized]);

    const handleOpenModal = (event) => {
        event.preventDefault();
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onChangeCurentDel = (id) => {
        setCurentDeliveryMetod(id);

        if(id == 1){
            setConfirmationCardOpen(false);
        }

    };

    const parseDeliveryData = ({ building_number, city, post_code, province, street }) => {
        setFieldValueMemoized('address', `${province} ${street}`);
        setFieldValueMemoized('city', city);
        setFieldValueMemoized('postal_code', post_code);
        setFieldValueMemoized('house_number', building_number);
    };

    const onPointCallback = (data) => {
        parseDeliveryData(data.address_details);
        setAdressDetails(data.address_details);
        setConfirmationCardOpen(true);
        handleCloseModal();
    };

    const renderInputs = () => {
        if (curentDeliveryMetod === 1) {            
            return (
                <div className={s.inputs_area_col}>
                    <p className={s.delivery_info_text}>
                        Wpisz adres, pod którym jesteś między 8:00 a 17:00. (Za dostawę firmy InPost)
                    </p>
                    <div className={s.address_input}>
                        <Field type="text" name="address" placeholder="Adres" />
                        <ErrorMessage name="address" component="div" className={s.errorMesage} />
                    </div>
                    <div className={s.houseNum_postIndex_row}>
                        <div>
                            <Field type="text" name="house_number" placeholder="Numer domu/lokalu" />
                            <ErrorMessage name="house_number" component="div" className={s.errorMesage} />
                        </div>
                        <div>
                            <Field type="text" name="postal_code" placeholder="Kod pocztowy" />
                            <ErrorMessage name="postal_code" component="div" className={s.errorMesage} />
                        </div>
                    </div>
                    <div className={s.city_input}>
                        <Field type="text" name="city" placeholder="Miasto" />
                        <ErrorMessage name="city" component="div" className={s.errorMesage} />
                    </div>
                </div>
            );
        } else if (curentDeliveryMetod === 2) {
            return (
                <div className={s.inPost_col}>
                    <button onClick={handleOpenModal} className={s.inPost_bt}>
                        Wybierz punkt
                    </button>

                    {isConfirmationCardOpen && <ConfirmationCard {...adressDetails}/>}

                    <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
                        <InpostGeowidget
                            token={TokenInPost}
                            onPoint={onPointCallback}
                            options={{
                                width: '100%',
                                height: '500px',
                            }}
                            config="parcelCollectPayment"
                        />
                    </ModalWindow>
                </div>
            );
        }
    };

    return (
        <div className={s.delivery_metods_col}>
            <div className={s.delivery_metods_body}>
                <input
                    type="radio"
                    name="productMetodsDelivery"
                    id="delivery-metods-1"
                    checked={curentDeliveryMetod === 1}
                    onChange={() => onChangeCurentDel(1)}
                />
                <label htmlFor="delivery-metods-1">
                    <div className={s.radio_mark_col}>
                        <div className={s.radio_mark}></div>
                        <img src={HouseIcon} alt="houseIcon" />
                    </div>
                    <p className={s.text_info}>Dostawa do domu</p>
                </label>
            </div>
            <div className={s.delivery_metods_body}>
                <input
                    type="radio"
                    name="productMetodsDelivery"
                    id="delivery-metods-2"
                    checked={curentDeliveryMetod === 2}
                    onChange={() => onChangeCurentDel(2)}
                />
                <label htmlFor="delivery-metods-2">
                    <div className={s.radio_mark_col}>
                        <div className={s.radio_mark}></div>
                        <img src={InPostIcon} alt="InPostIcon" />
                    </div>
                    <p className={s.text_info}>Odbiór Automat InPost - Paczkomat/PaczkoPunk</p>
                </label>
            </div>
            <div className={s.inputs_area}>{renderInputs()}</div>
        </div>
    );
};

export default DeliveryMetods;