import {useState, useEffect} from 'react'
import s from './PaymentMetods.module.scss';
import PayUIcon from '../../../resources/icon/PayUIcon.png';
import PayonDelivery from '../../../resources/icon/paymentOnDeliveryIcon.png';

const variantPayment = [
    {
        'data': 'payu',
        'title': 'PayU',
        'price': "0",
        'img': PayUIcon
    },
    {
        'data': 'payment_on_delivery',
        'title': 'Przesyłka za pobraniem',
        'price': "19.0",
        'img': PayonDelivery
    }
]
const PaymentMetods = ({setFieldValue}) => {
    const [currentPayment, setCurrentPayment] = useState();

    const onChangeCurentPayment = (paymentType) =>{
        setCurrentPayment(paymentType);
        setFieldValue('payment_type', currentPayment);
    }

    useEffect(()=>{
        onChangeCurentPayment(variantPayment[0].data)
    }, [])

    useEffect(()=>{
        setFieldValue('payment_type', currentPayment);
    },[setFieldValue,currentPayment])

    return ( 
        <div className={s.payment_col}>
            {variantPayment.map(({data, title, price, img}) => (
                
                <div className={s.payment_variant_body} key={data}>
                    <input
                            type="radio"
                            name="paymentType"
                            id={`payment-variant-${data}`}
                            checked={currentPayment === data}
                            onChange={() =>onChangeCurentPayment(data)}
                    />
                    <label htmlFor={`payment-variant-${data}`}>
                        <div className={s.radio_mark_col}>
                            <div className={s.radio_mark}></div>
                            <img src={img} alt={data} />
                        </div>
                        <div className={s.text_info}>
                            <p className={s.name_payment}>{title}</p>
                            <p className={s.price_info}>
                                {price} zł
                            </p>
                        </div>
                    </label>
                </div>
            ))}
        </div>
    )
}

export default PaymentMetods