import React from 'react';
import s from './Gallery.module.scss';

const Gallery = ({productImg}) => {
    
return (
    <section>
        <div className={s.container}>
            <h2>Galeria</h2>

            <div className={s.gallery_row}>
                {
                    productImg.map(({image, id}) => (
                        <div key={id} className={s.gallery_item}>
                            <img src={image} alt={`GalleryItem${id + 1}`} loading="lazy"/>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
  )
}

export default Gallery