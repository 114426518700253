import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import s from "./ProductSlider.module.scss";

const ProductSlider = ({ imgs }) => {
  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState(0);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const paginate = (newDirection) => {
    setDirection(newDirection);
    setCurrent((prev) => (prev + newDirection + imgs.length) % imgs.length);
  };

  return (
    <div className={s.carousel}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={current}
          src={imgs[current].image}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "tween", duration: 0.5 },
            opacity: { duration: 0.5 },
          }}
          className={s.image}
          loading="lazy"
        />
      </AnimatePresence>
      <div className={s.next} onClick={() => paginate(1)}>
        ›
      </div>
      <div className={s.prev} onClick={() => paginate(-1)}>
        ‹
      </div>
      <div className={s.dots}>
        {imgs.map((_, index) => (
          <span
            key={index}
            className={`${s.dot} ${index === current ? s.active : ""}`}
            onClick={() => setCurrent(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ProductSlider;
