import React from 'react'
import PageNavigation from '../../components/UI/PageNavigation'
import s from './WithdrawalPage.module.scss'

const WithdrawalPage = () => {

 
  return (

    <main>
        <section>

        <div className={s.container}>

            <PageNavigation />
            
            <h3 className={s.title}>
                Regulamin
            </h3>

            <ul className={s.text_area}>
                <li>Prawo do odstąpienia od umowy
                    <ul>
                        <li>Klient ma prawo odstąpić od umowy sprzedaży zawartej na odległość bez podania przyczyny w terminie 14 dni od dnia otrzymania towaru.</li>
                        <li>Aby skorzystać z prawa do odstąpienia od umowy, klient musi poinformować NETIFY Sp. z o.o. o swojej decyzji w drodze jednoznacznego oświadczenia (np. pismo wysłane pocztą, faksem lub pocztą elektroniczną na adres <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a>).</li>
                    </ul>
                </li>
                <li>Skutki odstąpienia od umowy
                    <ul>
                        <li>W przypadku odstąpienia od umowy zwracamy wszystkie otrzymane od klienta płatności, w tym koszty dostawy (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez klienta sposobu dostawy innego niż najtańszy zwykły sposób dostawy oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o decyzji klienta o wykonaniu prawa odstąpienia od umowy.</li>
                        <li>Zwrotu płatności dokonamy przy użyciu takich samych metod płatności, jakie zostały przez klienta użyte w pierwotnej transakcji, chyba że klient wyraźnie zgodził się na inne rozwiązanie; w każdym przypadku klient nie poniesie żadnych opłat w związku z tym zwrotem.</li>
                        <li>Możemy wstrzymać się ze zwrotem płatności do czasu otrzymania towaru lub do czasu dostarczenia nam dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.</li>
                    </ul>
                </li>
                <li>Zwrot towarów
                    <ul>
                        <li>Klient proszony jest o kontakt z nami drogą elektroniczną na adres: <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a> в celu uzyskania dalszych instrukcji dotyczących zwrotu towaru.</li>
                        <li>Klient będzie musiał ponieść bezpośrednie koszty zwrotu towarów.</li>
                        <li>Klient odpowiada tylko za zmniejszenie wartości towarów wynikające z korzystania z nich w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i funkcjonowania towarów.</li>
                    </ul>
                </li>
                <li>Wyjątki od prawa odstąpienia od umowy
                    <ul>
                        <li>Prawo odstąpienia od umowy nie przysługuje w odniesieniu do umów:</li>
                        <ul>
                            <li>o świadczenie usług, jeżeli NETIFY Sp. z o.o. wykonało w pełni usługę za wyraźną zgodą klienta;</li>
                            <li>w których przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji klienta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;</li>
                            <li>w których przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;</li>
                            <li>w których przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami.</li>
                        </ul>
                    </ul>
                </li>
                <li>
                    Jeśli masz jakiekolwiek pytania dotyczące naszej Polityki Zwrotów, prosimy o kontakt pod adresem e-mail: <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a>.
                </li>
            </ul>

            </div>

        </section>

    </main>
  )
}

export default WithdrawalPage