import { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import s from "./MainBaner.module.scss";
import Loader from "../Loader/Loader";

const MainBaner = () => {
  const [banners, setBanners] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_TRENDYTU_API_URL}/api/banners/`
        );
        setBanners(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <div>Error: {error}</div>;
  } else if (!banners.length) {
    return <p>No data available</p>;
  }

  return (
    <section>
      <div className={s.container}>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2400,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: `.${s.next}`,
            prevEl: `.${s.prev}`,
          }}
          className={s.carousel}
        >
          {banners.map((banner, index) => (
            <SwiperSlide key={index} className={s.swiper_slide_item}>
              <img
                src={banner.banner_img}
                alt={`Banner ${index + 1}`}
                className={s.image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={`${s.next} swiper-button-next`}></div>
        <div className={`${s.prev} swiper-button-prev`}></div>
      </div>
    </section>
  );
};

export default MainBaner;
