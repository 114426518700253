import React from 'react'
import PageNavigation from '../../components/UI/PageNavigation'
import s from './PrivacyPolicyPage.module.scss'

const PrivacyPolicyPage = () => {


  return (

    <main>
        <section>

          <div className={s.container}>

          <PageNavigation/>

            <h3 className={s.title}>
              Polityka prywatności
            </h3>

            <ul className={s.text_area}>
                <li>Administrator danych osobowych
                    <ul>
                        <li>Administratorem danych osobowych jest NETIFY Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, przy ul. Elektronowej 2, 03-219 Warszawa, Polska.</li>
                        <li>Kontakt z administratorem danych możliwy jest poprzez e-mail: <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a>.</li>
                    </ul>
                </li>
                <li>Cele przetwarzania danych osobowych
                    <ul>
                        <li>Dane osobowe klientów są przetwarzane w celu realizacji umowy sprzedaży, obsługi zamówień, realizacji zwrotów i reklamacji, a także w celach marketingowych, jeśli klient wyraził na to zgodę.</li>
                        <li>Dane mogą być również przetwarzane w celu spełnienia obowiązków prawnych ciążących na administratorze.</li>
                    </ul>
                </li>
                <li>Rodzaje przetwarzanych danych osobowych
                    <ul>
                        <li>Przetwarzamy następujące dane osobowe klientów:</li>
                        <ul>
                            <li>Imię i nazwisko</li>
                            <li>Adres zamieszkania</li>
                            <li>Adres e-mail</li>
                            <li>Numer telefonu</li>
                            <li>Dane dotyczące płatności (np. numer konta bankowego)</li>
                            <li>Dane dotyczące zamówień</li>
                        </ul>
                    </ul>
                </li>
                <li>Podstawy prawne przetwarzania danych
                    <ul>
                        <li>Przetwarzanie danych osobowych odbywa się na podstawie:</li>
                        <ul>
                            <li>Art. 6 ust. 1 lit. b) RODO – przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą.</li>
                            <li>Art. 6 ust. 1 lit. c) RODO – przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze.</li>
                            <li>Art. 6 ust. 1 lit. a) RODO – na podstawie zgody osoby, której dane dotyczą, w celach marketingowych.</li>
                        </ul>
                    </ul>
                </li>
                <li>Odbiorcy danych osobowych
                    <ul>
                        <li>Dane osobowe klientów mogą być przekazywane następującym odbiorcom:</li>
                        <ul>
                            <li>Firmom kurierskim i pocztowym realizującym dostawy zamówień.</li>
                            <li>Podmiotom obsługującym płatności elektroniczne.</li>
                            <li>Podmiotom świadczącym usługi księgowe i prawne.</li>
                            <li>Organom administracji publicznej, jeżeli obowiązek przekazania danych wynika z przepisów prawa.</li>
                        </ul>
                    </ul>
                </li>
                <li>Prawa osób, których dane dotyczą
                    <ul>
                        <li>Klientom przysługują następujące prawa związane z przetwarzaniem danych osobowych:</li>
                        <ul>
                            <li>Prawo dostępu do swoich danych osobowych.</li>
                            <li>Prawo do sprostowania danych osobowych.</li>
                            <li>Prawo do usunięcia danych osobowych.</li>
                            <li>Prawo do ograniczenia przetwarzania danych osobowych.</li>
                            <li>Prawo do przenoszenia danych osobowych.</li>
                            <li>Prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych.</li>
                            <li>Prawo do cofnięcia zgody na przetwarzanie danych osobowych w dowolnym momencie.</li>
                        </ul>
                    </ul>
                </li>
                <li>Okres przechowywania danych
                    <ul>
                        <li>Dane osobowe klientów przechowywane są przez okres niezbędny do realizacji celów, dla których zostały zebrane, a następnie przez okres wymagany przez przepisy prawa lub do momentu wycofania zgody na przetwarzanie danych.</li>
                    </ul>
                </li>
                <li>Środki ochrony danych osobowych
                    <ul>
                        <li>NETIFY Sp. z o.o. stosuje odpowiednie środki techniczne i organizacyjne w celu zapewnienia ochrony przetwarzanych danych osobowych przed ich utratą, niewłaściwym wykorzystaniem, nieautoryzowanym dostępem, ujawnieniem, zmianą lub zniszczeniem.</li>
                    </ul>
                </li>
                <li>
                    Jeśli masz jakiekolwiek pytania dotyczące naszej Polityki Ochrony Danych Osobowych, prosimy o kontakt pod adresem e-mail: <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a>.
                </li>
            </ul>

          </div>

        </section>

    </main>
  )
}

export default PrivacyPolicyPage