import React from "react";
import { Link } from "react-router-dom";
import DiscountButton from "../UI/DiscountButton/DiscountButton";
import StarRating from "../UI/StarRating/StarRating";
import s from "./ShowProducts.module.scss";

const ShowProducts = ({ products }) => {
  return (
    <section id="showProduct">
      <div className={s.container}>
        <h2>Popularne towary</h2>

        <div className={s.product_row}>
          {products.map(
            ({
              id,
              description,
              images,
              avg_rating_stars,
              discount,
              price,
              slug,
              title,
            }) => {
              return (
                <div className={s.card} key={id}>
                  <Link to={`/${slug}`}>
                    <div className={s.card_body}>
                      <img
                        src={images[0].image}
                        alt="productImg"
                        className={s.product_img}
                      />
                      <p className={s.name}>{title}</p>
                      <p className={s.characteristics}>{description}</p>

                      <div className={s.rating_col}>
                        <StarRating rating={avg_rating_stars} size="14px" />
                        <p>{avg_rating_stars}/5</p>
                      </div>
                    </div>
                    <DiscountButton
                      originalPrice={price}
                      discountPercent={discount}
                    />
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default ShowProducts;
