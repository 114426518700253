import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProductShow from '../components/Sections/ProductShow/ProductShow';
import Reviews from '../components/Sections/Reviews/Reviews';
import AboutProduct from "../components/Sections/AboutProduct/AboutProduct";
import Range from "../components/Sections/Range/Range";
import Gallery from "../components/Sections/Gallery/Gallery";
import OrderForm from "../components/Sections/OrderForm/OrderForm";
import Loader from '../components/UI/Loader/Loader';
import ErrorMesage from '../components/UI/ErrrorMesage/ErrorMesage';
import PaymentNavigator from '../components/UI/PaymentNavigator/PaymentNavigator';
import { useProduct } from '../context/ProductContext';

const ProductDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { productSlug } = useParams();
  const { setProductSlug } = useProduct();
  const [isPaymentNavigatorVisible, setIsPaymentNavigatorVisible] = useState(true);

  useEffect(() => {
    setProductSlug(productSlug);

    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_TRENDYTU_API_URL}/api/products/${productSlug}/`);
        setData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productSlug, setProductSlug]);

  useEffect(() => {
    const handleScroll = () => {
      const orderFormElement = document.getElementById('orderForm');
      if (orderFormElement) {
        const rect = orderFormElement.getBoundingClientRect();
        if (rect.top <=300) {
          setIsPaymentNavigatorVisible(false);
        } else {
          setIsPaymentNavigatorVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const discountedPrice = useMemo(() => {
    if (data) {
      return (data.price - (data.price * data.discount / 100)).toFixed(2);
    }
    return null;
  }, [data]);

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <ErrorMesage error={error} />;
  } else if (!data) {
    return <p>No data available</p>;
  }

  return (
    <main>
      {data.images && (
        <ProductShow 
          productImg={data.images} 
          discount={data.discount} 
          price={data.price} 
          description={data.description} 
          starRating={data.reviews}
        />
      )}
      {data.details && data.images[1] && (
        <AboutProduct 
          details={data.details} 
          productImg={data.images[1].image}
          productName={data.title} 
        />
      )}
      {data.variations && (
        <Range 
          variations={data.variations} 
          discount={data.discount} 
          price={data.price} 
        />
      )}
      {data.recommend_products && data.variations && (
        <OrderForm 
          recommend_products={data.recommend_products}
          variations={data.variations} 
          price={discountedPrice}
          product_id = {data.id}
        />
      )}
      {data.reviews && (
        <Reviews
          productReviews={data.reviews}
        />
      )}
      {data.images && (
        <Gallery
          productImg={data.images} 
        />
      )}      
      {data.price && (
         <PaymentNavigator
          discount={data.discount} 
          price={data.price}
          isVisible={isPaymentNavigatorVisible}
        />
      )}
    </main>
  );
};

export default ProductDetails;
