import React from 'react'

const NotFoundPage = () => {
  return (
    <div className="">
        Sorry page not found
    </div>
  )
}

export default NotFoundPage