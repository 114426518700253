import React from 'react'
import s from "./Faq.module.scss"

const dataFaq = [
    {
        qestion: 'Która firma dostarcza produkty?',
        answer: 'InPost dostarczy Ci Twoje zamówienie, poinformuje Cię o tym SMS-em w dniu dostawy.',
    },
    {
        qestion: 'Czy mogę zwrócić mój produkt?',
        answer: 'Oczywiście odeślij nam produkt, a my zwrócimy Ci poniesioną kwotę zakupu.',
    },
    {
        qestion: 'Jakie są opcje płatności?',
        answer: 'Możesz zapłacić kartą kredytową, przez Paypal lub gotówką przy odbiorze.',
    },
    {
        qestion: 'Co oznacza 100% gwarancja?',
        answer: 'W przypadku niezadowolenia z zakupu zwrócimy pieniądze bez pytania!',
    }
]

const Faq = () => {
  return (
    <section>
        <div className={s.container}>

            <div className={s.title_col}>
                <h2>
                    Pytania i odpowiedzi
                </h2>
                <p>
                    Bolea obsługuje 1 651 467 klientów.
                </p>
            </div>

            <div className={s.Faq_col}>

                {
                    dataFaq.map(({qestion,answer},i)=>{
                        return(
                            <div className={s.item} key={i}>
                                <p className={s.qestion}>{qestion}</p>
                                <p className={s.answer}>{answer}</p>
                            </div>
                        )
                    })
                }
            </div>

            <div className={s.feedback_col}>

                <a href="mailto:netifypl@gmail.com" className={s.feedback_email_bt}>
                    Napisz, żeby zadać pytanie
                </a>

                <p>
                    Na wszystkie pytania odpowiadamy natychmiast.
                </p>
            </div>
        </div>
    </section>
  )
}

export default Faq