import React from 'react'
import PageNavigation from '../../components/UI/PageNavigation'
import s from './ShipmentPage.module.scss'

const ShopRules = () => {

  return (

    <main>
        <section>

          <div className={s.container}>

           <PageNavigation/>

            <h3 className={s.title}>
              Wysyłka
            </h3>

            <ul className={s.text_area}>
                <li>Regulamin Dostawy Netify Sp. z o.o.</li>
                <li>
                    Postanowienia ogólne
                    <ul>
                        <li>Niniejszy regulamin określa zasady dostawy towarów zamówionych w firmie Netify Sp. z o.o., z siedzibą w UL. ELEKTRONOWA 2, 03-219 WARSZAWA, NIP: 5243000103, REGON: 527929200.</li>
                        <li>Netify Sp. z o.o. realizuje dostawy za pośrednictwem InPost Paczkomaty oraz dostawy kurierskie.</li>
                    </ul>
                </li>
                <li>
                    Rodzaje dostawy
                    <ul>
                        <li>
                            InPost Paczkomaty
                            <ul>
                                <li>Dostawa za pośrednictwem InPost Paczkomaty umożliwia odbiór przesyłki w wybranym paczkomacie 24 godziny na dobę, 7 dni w tygodniu.</li>
                                <li>Koszt dostawy do paczkomatu jest zależny od wielkości i wagi zamówienia oraz jest podawany podczas składania zamówienia.</li>
                                <li>Czas realizacji dostawy za pośrednictwem InPost Paczkomaty wynosi zazwyczaj od 1 do 3 dni roboczych od momentu nadania przesyłki.</li>
                            </ul>
                        </li>
                        <li>
                            Dostawa kurierem
                            <ul>
                                <li>Netify Sp. z o.o. oferuje również dostawę kurierską, która realizowana jest przez renomowane firmy kurierskie.</li>
                                <li>Koszt dostawy kurierskiej jest zależny od wielkości i wagi zamówienia oraz jest podawany podczas składania zamówienia.</li>
                                <li>Czas realizacji dostawy kurierskiej wynosi zazwyczaj od 1 do 3 dni roboczych od momentu nadania przesyłki.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    Warunki dostawy
                    <ul>
                        <li>Zamówienia są realizowane w dni robocze, od poniedziałku do piątku.</li>
                        <li>Zamówienia złożone w dni robocze do godziny 12:00 są zazwyczaj nadawane tego samego dnia. Zamówienia złożone po godzinie 12:00 oraz w weekendy i święta są realizowane następnego dnia roboczego.</li>
                        <li>Klient otrzymuje informację o nadaniu przesyłki oraz numer śledzenia, umożliwiający monitorowanie statusu dostawy.</li>
                        <li>W przypadku nieodebrania przesyłki w terminie, paczka zostaje zwrócona do Netify Sp. z o.o. Koszt ponownej wysyłki ponosi klient.</li>
                    </ul>
                </li>
                <li>
                    Reklamacje dotyczące dostawy
                    <ul>
                        <li>W przypadku stwierdzenia uszkodzenia przesyłki lub jej niezgodności z zamówieniem, klient powinien niezwłocznie skontaktować się z Netify Sp. z o.o. pod adresem e-mail: <a href="mailto:netifypl@gmail.com">netifypl@gmail.com</a> lub telefonicznie: +48575627997.</li>
                        <li>Reklamacje dotyczące dostawy rozpatrywane są w ciągu 14 dni od daty ich zgłoszenia.</li>
                    </ul>
                </li>
                <li>
                    Postanowienia końcowe
                    <ul>
                        <li>Netify Sp. z o.o. zastrzega sobie prawo do zmiany niniejszego regulaminu. Zmiany wchodzą w życie z dniem ich opublikowania na stronie internetowej firmy.</li>
                        <li>W sprawach nieuregulowanych niniejszym regulaminem mają zastosowanie odpowiednie przepisy prawa polskiego.</li>
                        <li>Regulamin obowiązuje od dnia [data wejścia w życie regulaminu].</li>
                    </ul>
                </li>
                <li>Dziękujemy za korzystanie z usług Netify Sp. z o.o.!</li>
            </ul>

          </div>

        </section>

    </main>
  )
}

export default ShopRules