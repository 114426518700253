import React from 'react';
import { motion } from 'framer-motion';
import s from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={s.loader_container}>
      {[...Array(5)].map((_, index) => (
        <motion.div
          key={index}
          className={s.loader_ball}
          animate={{
            y: [0, -20, 0],
            opacity: [1, 0.5, 1],
          }}
          transition={{
            duration: 0.6,
            ease: 'easeInOut',
            repeat: Infinity,
            delay: index * 0.2,
          }}
        />
      ))}
    </div>
  );
};

export default Loader;