import React from 'react';
import { Link } from 'react-router-dom';
import s from './Footer.module.scss';
import Facebook from '../../resources/icon/facebook_icon.png';
import Whatsapp from '../../resources/icon/whatsapp_ico.svg';
import Logo from'../../resources/header/trendyto_small_logo.png';
import Pl from '../../resources/icon/Pl.svg';


const Footer = () => {
return (
    <footer>

        <div className={s.container}>
            
            <div className={s.social_col}>

                <img src={Logo} alt="smallLogo" className={s.logo_img} />

                <div class={s.social_icons}>
                    <a href="https://www.facebook.com/trendytupl/" target="_blank" rel="noopener noreferrer" >
                        <img src={Facebook} alt="Facebook"/>
                    </a>
                    {/* <a href="https://www.viber.com" target="_blank" rel="noopener noreferrer">
                        <img src={Whatsapp} alt="Whatsapp"/>
                    </a> */}
                </div>

            </div>

            <div className={s.footer_links_col}>
                <Link to='/paymentmetods'>Sposoby płatności</Link>
                <Link to='/shipment'>Wysyłka</Link>
                <Link to='/statutes'>Regulamin</Link>
                <Link to='/privatepolice'>Polityka prywatności</Link>
                <Link to='/withdrawal'>Zwrot</Link>
            </div>

            <div className={s.contact_col}>
                <h3>KONTAKT</h3>

                <a href="mailto:netifypl@gmail.com" class="contact-link">netifypl@gmail.com</a>

                <p>UL. ELEKTRONOWA 2, 03-219 WARSZAWA</p>
            </div>
                
            <div className={s.guarantees_col}>
                <p>
                    Europejski przedsiębiorca Super jakość.
                </p>

                <p>
                    100% bezpiecznych zakupów Zwrotu bez podania przyczyny!
                </p>

                <img src={Pl} alt="paymentMetods"/>

            </div>

        </div>

        <div className={s.copyright_notice}>
            <p>© 2022-2024 All rights reserved</p>
        </div>

    </footer>
  )
}

export default Footer