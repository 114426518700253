import React from 'react'
import s from './AboutProduct.module.scss';
import DeliveryIco from  '../../../resources/icon/delivery_bike.png'
import SecurityIco from  '../../../resources/icon/security.png'
import FireIco from  '../../../resources/icon/fire.png'



const AboutProduct = ({details, productImg, productName }) => {

return (
    <section>
        <div className={s.container}>

            <h2>
                O produkcie
            </h2>

            <div className={s.product_col}>

                <div className={s.product_img_col}>
                    <img src={productImg} alt="productImg" loading="lazy"/>
                </div>

                <div className={s.product_characteristics}>
                    <h3>
                        {productName}
                    </h3>

                    <div className={s.characteristics_cards}>
                        {
                            details.map(({id, name, icon, description}) =>{
                                return(
                                    <div className={s.card} key={id}>
                                        <img src={icon} alt="icon" />
            
                                        <p>
                                            <span>{name}</span>
                                            {description}
                                        </p>
            
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div className={s.advantages_col}>
                        <div className={s.box}>

                            <img src={DeliveryIco} alt="deliveruIcon" />

                            <p>Szybka dostawa</p>

                        </div>

                        <div className={s.box}>

                            <img src={SecurityIco} alt="securityIcon" />

                            <p>30-dniowa gwarancja</p>
                            
                        </div>

                        <div className={s.box}>

                            <img src={FireIco} alt="securityIcon" />

                            <p>-40%</p>
                            
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </section>

  )
}

export default AboutProduct